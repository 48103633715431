import { axios } from '@core/axios';
import { from, map, Observable } from 'rxjs';
import { SecurityType } from '@model/enum/security-type.enum';
import { CheckReservedSymbolAdminRequest, CheckReservedSymbolRequest } from '@model/request/check-reserved-symbol.request';
import { ReservedSymbolRequestType } from '@model/request/reserved-symbol.request';
import { DIPOBackBaseResponse, MessageResponse } from '@model/response/@base-response/dipo-back-base-response';
import { CheckReservedSymbolAdminResponse, CheckReservedSymbolResponse } from '@model/response/check-reserved-symbol.response';
import { ActiveSymbolResponse } from '@model/response/active-symbol.response';
import { ReservedSymbolResponse } from '@model/response/reserved-symbol';
import { ApplicationAccess } from '@model/response/check-application-access';
import { DraftProfileResponse } from '@model/response/draft-profile.response';

const basePath = '/reservedSymbol';
export class ReservedSymbolService {
  public static getSymbol<T extends ReservedSymbolResponse>(id: number, securityType: SecurityType): Observable<DraftProfileResponse<T>> {
    const url = `${basePath}/${securityType}/${id}`;
    return from(axios.get<DIPOBackBaseResponse<DraftProfileResponse<T>>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static create(req: ReservedSymbolRequestType, securityType: SecurityType): Observable<{ id: number }> {
    const url = `${basePath}/${securityType}`;
    return from(axios.post<DIPOBackBaseResponse<{ id: number }>>(url, req)).pipe(map(({ data }) => data.data[0]));
  }

  public static updateFull(req: ReservedSymbolRequestType, id: number, securityType: SecurityType): Observable<MessageResponse> {
    const url = `${basePath}/${securityType}/full/${id}`;
    return from(axios.put<MessageResponse>(url, req)).pipe(map(({ data }) => data));
  }

  public static updateDraft(req: ReservedSymbolRequestType, id: number, securityType: SecurityType): Observable<MessageResponse> {
    const url = `${basePath}/${securityType}/${id}/draft/`;
    return from(axios.put<MessageResponse>(url, req)).pipe(map(({ data }) => data));
  }

  public static canDelete(id: number): Observable<boolean> {
    const url = `${basePath}/${id}/delete/verify`;
    return from(axios.get<DIPOBackBaseResponse<boolean>>(url)).pipe(map(({ data }) => data.data[0]));
  }

  public static delete(id: number, reason: string): Observable<MessageResponse> {
    const url = `${basePath}/${id}`;
    return from(axios.delete<MessageResponse>(url, { data: { reason: reason } })).pipe(map(({ data }) => data));
  }

  public static checkReservedSymbol(params: CheckReservedSymbolRequest): Observable<CheckReservedSymbolResponse> {
    const buildParams = {
      ...params,
      typeOfSecurities: params.typeOfSecurities.toUpperCase(),
    };
    const url = `${basePath}/check`;
    return from(axios.get<DIPOBackBaseResponse<CheckReservedSymbolResponse>>(url, { params: buildParams })).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static checkReservedSymbolAdmin(params: CheckReservedSymbolAdminRequest): Observable<CheckReservedSymbolAdminResponse> {
    const url = `${basePath}/check-admin`;
    return from(axios.get<DIPOBackBaseResponse<CheckReservedSymbolAdminResponse>>(url, { params })).pipe(map(({ data }) => data.data[0]));
  }

  public static getSymbolsPO(typeOfSecurity: SecurityType): Observable<{ id: number; symbol: string }[]> {
    const buildParams = {
      typeOfSecurities: typeOfSecurity.toUpperCase(),
    };
    const url = `${basePath}/symbol-po`;
    return from(axios.get<DIPOBackBaseResponse<{ id: number; symbol: string }[]>>(url, { params: buildParams })).pipe(
      map(({ data }) => data.data[0])
    );
  }

  public static activeSymbol(req: { id: number | null; symbol: string }, symbolId: number): Observable<ActiveSymbolResponse> {
    const url = `${basePath}/${symbolId}/active`;
    return from(axios.post<DIPOBackBaseResponse<ActiveSymbolResponse>>(url, req)).pipe(map(({ data }) => data.data[0]));
  }

  public static renewSymbol(symbolId: number): Observable<MessageResponse> {
    const url = `${basePath}/${symbolId}/extend`;
    return from(axios.put<MessageResponse>(url)).pipe(map(({ data }) => data));
  }

  public static checkApplicationAccess(id: number): Observable<ApplicationAccess> {
    const url = `${basePath}/${id}/check-application-access`;
    return from(axios.get<DIPOBackBaseResponse<ApplicationAccess>>(url)).pipe(map(({ data }) => data.data[0]));
  }
}
